import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import Login from "./Login";
import "./assets/scss/index.scss";
import "font-awesome/css/font-awesome.css";
import "react-datepicker/dist/react-datepicker.css";
import { Auth0Provider } from "@auth0/auth0-react";
import APIConfig from "./APIConfig";

Amplify.configure({
  API: APIConfig
});

const AppWithLogin: React.FC = () => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
    redirectUri={process.env.REACT_APP_REDIRECT}
  >
    <Login>
      <App />
    </Login>
  </Auth0Provider>
);
ReactDOM.render(<AppWithLogin />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
