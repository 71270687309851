export const getDashboardEvents = `query DashboardEvents($input: EventsDashboardInput!) {
  eventsDashboard(input: $input) { 
    id
    customer_id
    plan_id
    hash
    pipeline_id
    pipeline {
      id
      customer_id
      plan_id
      hash
      name
      description
      window_start_time
      window_end_time
      patching_status
      parallel
      policy
      machines {
        id
        name
        order
        pre_host_hooks {
          type
          source
          method
          status_code
        }
        post_host_hooks {
            type
            source
            method
            status_code
        }
        status
        manual_status
        action_taken
        assignee
        s3_custom_script
      }
      pre_hooks {
        type
        source
        method
        status_code
      }
      post_hooks {
          type
          source
          method
          status_code
      }
      notification_groups
      machines_tag {
        tag_list {
          values
          key
        }
        iam_role
        pre_host_hooks {
            type
            source
            method
            status_code
        }
        post_host_hooks {
            type
            source
            method
            status_code
        }
        allow_reboot
        regions
        s3_custom_script
      }
      dry_run
      scan_event_id
      upcoming_notification_time
      errors
      start_time
      end_time
      created_at
      updated_at
    }
    name
    description
    window_start_time
    window_end_time
    patching_status
    parallel
    policy
    machines {
      id
      name
      order
      pre_host_hooks {
        type
        source
        method
        status_code
      }
      post_host_hooks {
          type
          source
          method
          status_code
      }
      status
      manual_status
      action_taken
      assignee
      s3_custom_script
    }
    pre_hooks {
      type
      source
      method
      status_code
    }
    post_hooks {
        type
        source
        method
        status_code
    }
    notification_groups
    machines_tag {
      tag_list {
        values
        key
      }
      iam_role
      pre_host_hooks {
          type
          source
          method
          status_code
      }
      post_host_hooks {
          type
          source
          method
          status_code
      }
      allow_reboot
      regions
      s3_custom_script
    }
    dry_run
    scan_event_id
    upcoming_notification_time
    errors
    start_time
    end_time
    s3_custom_script
    created_at
    updated_at
  }
   customers { 
      id
      name
    }
  }
  `;
