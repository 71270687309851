type statesOptions = {
  [key: string]: { name: string; className: string };
};

export const PATCHING_STATES_MAPPING: statesOptions = {
  NEW: { name: "upcoming", className: "is-info" },
  IN_PROGRESS: { name: "in progress", className: "is-warning" },
  DONE: { name: "success", className: "is-success" },
  DONE_MANUAL: { name: "success manual", className: "is-success" },
  DONE_PARTIAL: { name: "partial success", className: "is-warning" },
  DONE_ERROR: { name: "failed", className: "is-danger" },
  DONE_ERROR_MANUAL: { name: "failed manual", className: "is-danger" },
  HOOK_ERROR: { name: "hook failed", className: "is-danger" },
  HOST_HOOK_ERROR: { name: "host hook failed", className: "is-danger" },
  DELETED: { name: "deleted", className: "is-danger" },
  UNKNOWN: { name: "not defined", className: "" },
  NOT_APPROVED: { name: "not approved", className: "is-warning" },
  MISSING: { name: "status missing", className: "is-info" }
};
