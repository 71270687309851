import React, { useState } from "react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { getDashboardEvents } from "../../../graphql/getDashboardEvents";
import moment from "moment";
import { Loading } from "../../../commonComponents/loading/Loading";
import DatePicker from "react-datepicker";
import CustomerSelect from "../components/CustomerSelect";
import Pagination from "../../../commonComponents/pagination/Pagination";
import Event from "../components/Event";
import StatusSelect from "../components/StatusSelect";
import { Error } from "../../../commonComponents/error/Error";

const EventsPage: React.FC = () => {
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().add(7, "days").toDate());
  const [customer, setCustomer] = useState("");
  const [status, setStatus] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const eventConfig = {
    start_date: startDate,
    end_date: endDate,
    customer_ids: customer ? [customer] : [],
    status: status ? status : null,
  };
  const PATCHING_AVAILABLE_STATES = [
    "NEW",
    "IN_PROGRESS",
    "DONE",
    "DONE_ERROR",
    "DELETED",
  ];

  const handleCustomerChange = (id: string) => {
    setCustomer(id);
    setCurrentPage(0);
  };

  const handleStatusChange = (name: string) => {
    setStatus(name);
    setCurrentPage(0);
  };

  const handleStartChange = (date: any) => {
    setStartDate(date);
    setCurrentPage(0);
  };

  const handleEndChange = (date: any) => {
    setEndDate(date);
    setCurrentPage(0);
  };

  return (
    <Connect
      query={graphqlOperation(getDashboardEvents, { input: eventConfig })}
    >
      {({
        data,
        errors,
        loading,
      }: {
        data: any;
        errors: Array<Error>;
        loading: boolean;
      }) => {
        const sortedData =
          data &&
          data.eventsDashboard &&
          data.eventsDashboard.sort(
            (a: any, b: any) =>
              new Date(a.window_start_time).getTime() -
              new Date(b.window_start_time).getTime()
          );
        const visibleData =
          sortedData &&
          sortedData.slice(
            currentPage * perPage,
            perPage * currentPage + perPage
          );
        const totalCount =
          data && data.eventsDashboard ? data.eventsDashboard.length : 0;
        const availablePages =
          data && data.eventsDashboard && data.eventsDashboard.length > 0
            ? Array.from(
                Array(Math.ceil(data.eventsDashboard.length / perPage)).keys()
              )
            : [];
        const customers = data.customers;
        return (
          <div className="main wide">
            <h1 className="title is-size-2">Events dashboard</h1>
            <div className="box">
              <nav className="level">
                <div className="level-left">
                  <div className="level-item">
                    <div className="level-item">
                      <div className="field">
                        <div className="control has-icons-left is-narrow-input">
                          <DatePicker
                            className="input "
                            dateFormat="dd/MM/yyyy"
                            todayButton="Today"
                            selected={startDate}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleStartChange}
                            placeholderText="Select start date ..."
                          />
                          <span className="icon is-small is-left is-search-icon">
                            <i className="fa fa-calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="level-item">
                      <div className="field">
                        <div className="control has-icons-left is-narrow-input">
                          <DatePicker
                            className="input "
                            dateFormat="dd/MM/yyyy"
                            todayButton="Today"
                            selected={endDate}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleEndChange}
                            placeholderText="Select end date ..."
                          />
                          <span className="icon is-small is-left is-search-icon">
                            <i className="fa fa-calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="level-item">
                    <StatusSelect
                      onChange={handleStatusChange}
                      value={status}
                      options={PATCHING_AVAILABLE_STATES}
                    />
                  </div>
                  <div className="level-item">
                    <CustomerSelect
                      onChange={handleCustomerChange}
                      value={customer}
                    />
                  </div>
                </div>
              </nav>
            </div>
            <div className="box">
              {errors &&
                errors.length > 0 &&
                errors.map((error) => <Error error={error} />)}
              <Loading loading={loading}>
                {data &&
                data.eventsDashboard &&
                data.eventsDashboard.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table is-fullwidth is-hoverable is-striped">
                      <thead>
                        <tr>
                          <th>Customer</th>
                          <th>Name</th>
                          <th>Id</th>
                          <th>Start time</th>
                          <th>Status</th>
                          <th>Dry run</th>
                          <th>Machines patched</th>
                          <th>Plan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {visibleData.map((event: any, index: any) => (
                          <Event
                            event={event}
                            key={index}
                            customers={customers}
                          />
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      setPerPage={setPerPage}
                      perPage={perPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      availablePages={availablePages}
                      totalCount={totalCount}
                    />
                  </div>
                ) : (
                  <div className="message is-warning">
                    <div className="message-body">Oops, nothing to show.</div>
                  </div>
                )}
              </Loading>
            </div>
          </div>
        );
      }}
    </Connect>
  );
};

export default EventsPage;
