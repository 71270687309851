import React from "react";

interface Props {
  perPage: number;
  currentPage: number;
  setPerPage: (value: number | ((prevVar: number) => number)) => void;
  setCurrentPage: (value: number | ((prevVar: number) => number)) => void;
  availablePages: Array<number>;
  totalCount: number;
}

const Pagination: React.FC<Props> = ({
  setPerPage,
  perPage,
  setCurrentPage,
  currentPage,
  availablePages,
  totalCount
}) => {
  return (
    <nav className="level">
      <div className="level-left">
        <React.Fragment>
          <span className="is-nav-title">Per page:</span>
          <nav
            className="pagination is-small"
            role="navigation"
            aria-label="pagination"
          >
            <ul className="pagination-list">
              <li>
                {[10, 20, 50].map((el, index) => (
                  <button
                    key={index}
                    className={`pagination-link ${el === perPage &&
                      `is-current`}`}
                    onClick={() => setPerPage(el)}
                  >
                    {el}
                  </button>
                ))}
              </li>
            </ul>
          </nav>
        </React.Fragment>
      </div>
      <nav
        className="pagination is-small"
        role="navigation"
        aria-label="pagination"
      >
        <ul className="pagination-list">
          <li>
            <button
              className="pagination-previous"
              title="This is the first page"
              onClick={e => {
                e.stopPropagation();
                setCurrentPage(currentPage - 1);
              }}
              disabled={currentPage < 1}
            >
              Previous
            </button>
          </li>
          {availablePages.map(el => (
            <li key={el}>
              <button
                className={`pagination-link ${el === currentPage &&
                  `is-current`}`}
                onClick={() => setCurrentPage(el)}
                onKeyDown={() => setCurrentPage(el)}
                type="button"
              >
                {el + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              className="pagination-next "
              onClick={e => {
                e.stopPropagation();
                setCurrentPage(currentPage + 1);
              }}
              disabled={currentPage + 1 >= totalCount / perPage}
            >
              Next page
            </button>
          </li>
        </ul>
      </nav>
    </nav>
  );
};

export default Pagination;
