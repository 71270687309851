import React from "react";
import { getCustomers } from "../../../graphql/getCustomers";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { Loading } from "../../../commonComponents/loading/Loading";

interface Props {
  onChange: (id: string) => void;
  value: string;
}

const CustomerSelect: React.FC<Props> = ({ onChange, value }) => {
  return (
    <Connect query={graphqlOperation(getCustomers)}>
      {({
        data,
        loading,
        error
      }: {
        data: any;
        error: Array<Error>;
        loading: boolean;
      }) => {
        return (
          <div className="select">
            <Loading loading={loading}>
              <select
                onChange={e => onChange(e.target.value)}
                defaultValue={value}
                disabled={
                  data && data.customers && data.customers.length > 0
                    ? false
                    : true
                }
              >
                {data && data.customers && data.customers.length > 0 ? (
                  <>
                    <option value="" key={-1}>
                      Select customer
                    </option>
                    {data.customers.map(
                      (
                        customer: { name: string; id: string },
                        index: string
                      ) => (
                        <option value={customer.id} key={index}>
                          {customer.name}
                        </option>
                      )
                    )}
                  </>
                ) : (
                  <option value="" key={-1}>
                    No customers
                  </option>
                )}
              </select>
            </Loading>
          </div>
        );
      }}
    </Connect>
  );
};

export default CustomerSelect;
