import React from "react";
import moment from "moment";
import EventStatus from "./EventStatus";

interface Props {
  event: any;
  customers: any[];
}

const Event: React.FC<Props> = ({ event, customers }) => {
  const allMachines = event.machines ? event.machines.length : 0;
  const succeedMachines =
    allMachines > 0
      ? event.machines.filter(
          (machine: any) =>
            machine.status && machine.status.startsWith(`Success`)
        ).length
      : 0;

  const customerName =
    customers.find(({ id }) => event.customer_id === id)?.name ||
    event.customer_id;
  return (
    <tr>
      <td>{customerName}</td>
      <td>{event.name}</td>
      <td>{event.id}</td>
      <td>
        {event.window_start_time
          ? moment(event.window_start_time).utc().format("DD-MM-YYYY, HH:mm:ss")
          : ""}
      </td>
      <td>
        <EventStatus
          status={event.patching_status ? event.patching_status : "MISSING"}
        />
      </td>
      <td>
        {event.dry_run ? (
          <i className="fa fa-check" />
        ) : (
          <i className="fa fa-times" />
        )}
      </td>
      <td>
        {succeedMachines} / {allMachines}
      </td>
      <td>
        {event.pipeline_id ? (
          <span>Pipeline: {event.pipeline_id}</span>
        ) : (
          event.plan_id
        )}
      </td>
    </tr>
  );
};

export default Event;
