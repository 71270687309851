import { withAuth0, Auth0ContextInterface } from '@auth0/auth0-react';
import React from "react";
import { NordcloudLogo } from "../../assets/nordcloudLogo";

interface Props {
  auth0: Auth0ContextInterface
}

const NavigationHeader: React.FC<Props> = ({ auth0 }) => {
  return (
    <nav id="navbar" className="navbar has-shadow is-spaced">
      <div className="navbar-brand">
        <div className="navbar-item">
          <NordcloudLogo fillColor="#161632" />
        </div>

        <div className="navbar-item navbar-user">
          <strong>{auth0.user?.name}</strong>
          <a className="nav-item"
            href="#logout"
            onClick={e => {
              e.preventDefault();
              auth0.logout();
            }}
          >
            Logout 
          </a>
        </div>
      </div>
    </nav>
  );
};

export default withAuth0(NavigationHeader);
