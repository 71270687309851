import React from "react";
import { PATCHING_STATES_MAPPING } from "../../../utils/constants";

interface Props {
  onChange: (name: string) => void;
  options: Array<string>;
  value: string;
}

const StatusSelect: React.FC<Props> = ({ onChange, options, value }) => {
  return (
    <div className="select">
      <select onChange={e => onChange(e.target.value)} defaultValue={value}>
        <option value="" key={-1}>
          Select status
        </option>
        {options.map((status: string, index: number) => {
          const statusMap = PATCHING_STATES_MAPPING[status]
            ? PATCHING_STATES_MAPPING[status].name
            : status;
          return (
            <option value={status} key={index}>
              {statusMap}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default StatusSelect;
