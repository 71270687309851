import React, { useState } from "react";

interface ErrorProps {
  error: Error;
}

export const Error: React.FC<ErrorProps> = ({ error }) => {
  const [visible, setVisible] = useState(true);
  const message = error.message ? error.message : "";
  const hide = () => setVisible(false);

  return (
    <>
      {visible && (
        <article className="message is-danger">
          <div className="message-header">
            <p>Oooops</p>
            <button className="delete" aria-label="delete" onClick={hide} />
          </div>
          <div className="message-body">{message}</div>
        </article>
      )}
    </>
  );
};
