import React from "react";
import EventsPage from "./views/events/containers/EventsPage";
import NavigationHeader from "./commonComponents/navigation/NavigationHeader";

const App: React.FC = () => {
  return (
    <div className="App">
      <NavigationHeader />
      <EventsPage />
    </div>
  );
};

export default App;
