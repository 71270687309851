import React, { useState, useEffect } from "react";
import { withAuth0, Auth0ContextInterface } from '@auth0/auth0-react';
import Amplify from "aws-amplify";
import { UserProvider } from "./context/User";
import APIConfig from "./APIConfig";

interface Props {
  auth0: Auth0ContextInterface
  children: JSX.Element;
}

const Login: React.FC<Props> = ({ auth0, children }) => {
  const [loggedIn, setLoggedIn] = useState(false);

  const checkCurrentUser = () => {
    if (!auth0.isAuthenticated && !auth0.isLoading) {
      auth0.loginWithRedirect();
    }

    if (auth0.isAuthenticated) {
      auth0.getIdTokenClaims().then(
        c => {
          APIConfig.graphql_headers = async () => ({
            Authorization: c.__raw
          }); 
          Amplify.configure(APIConfig);
          setLoggedIn(true);
        }
      )
    }
  }

  useEffect(() => checkCurrentUser());
  return loggedIn ? (
    <UserProvider email={auth0.user?.email || ""}>{children}</UserProvider>
  ) : (
    <div />
  );
};

export default withAuth0(Login);
