import React from "react";
import { PATCHING_STATES_MAPPING } from "../../../utils/constants";

interface Props {
  status: string;
}

const EventStatus: React.FC<Props> = ({ status }) => {
  const statusMap = PATCHING_STATES_MAPPING[status]
    ? PATCHING_STATES_MAPPING[status]
    : { className: "", name: "" };
  return (
    <div className={`tag ${statusMap.className}`}>
      {statusMap.name.toUpperCase()}
    </div>
  );
};

export default EventStatus;
